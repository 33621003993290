<!--
 * @Description: 智能 IM 介绍页
 * @Date: 2024-03-21 16:32:08
 * @LastEditTime: 2024-03-25 17:41:54
-->
<template>
  <div class="intelligent-im">
    <view-header :isHome="false" ref="navheader" />

    <!-- 模块 1 -->
    <section class="banner-wrap">
      <div class="show-content">
        <img
          class="banner1"
          src="@/assets/img/intelligent-im/floor-1-left.png"
        />
        <img
          class="banner2"
          src="@/assets/img/intelligent-im/floor-1-right.png"
        />

        <!-- 跳转 -->
        <div class="jump-to-btn" @click="jump">
          <span class="text">申请试用</span>

          <span class="icon"></span>
        </div>
      </div>

      <div class="banner-header_cont">
        <div class="banner-title">跨境智能IM</div>
        <div class="banner-text">
          <div class="banner-item">
            利用商品已有信息及历史对话记录，快速搭建机器人对话。促进买卖家高效沟通、社媒评论维护等
          </div>
        </div>
      </div>
    </section>

    <!-- 核心功能 -->
    <section class="core-function">
      <common-title title="核心功能"></common-title>

      <div class="site-section__holder">
        <div class="sticky-media-holder">
          <!-- 核心内容介绍 -->
          <div class="content-left">
            <div class="text-block__target">
              <h3 class="text-block__subtitle">快速对话机器人搭建</h3>

              <div class="text-block__text">
                <p>
                  5小时快速搭建属于自己的对话机器人，用户“QA对”结构化知识依赖低；接口多场景对接。
                </p>
              </div>
            </div>

            <div class="text-block__target">
              <h3 class="text-block__subtitle">结构化知识挖掘</h3>

              <div class="text-block__text">
                <p>
                  提供word/pdf知识文档、历史对话记录、商详页信息的结构化知识挖掘。
                </p>
              </div>
            </div>

            <div class="text-block__target">
              <h3 class="text-block__subtitle">Feitian-LLM多轮对话</h3>

              <div class="text-block__text">
                <p>
                  依赖Feitian-LLM函数调用功能，实现对话式进行如物流查询、商品优惠信息查询等（支持接口调用）
                </p>
              </div>
            </div>

            <div class="text-block__target">
              <h3 class="text-block__subtitle">对话日志分析</h3>

              <div class="text-block__text">
                <p>
                  对话日志分析，了解买家关注点；买家问题总结及分级，不错过重要信息。
                </p>
              </div>
            </div>

            <div class="text-block__target">
              <h3 class="text-block__subtitle">中英文对话</h3>

              <div class="text-block__text">
                <p>机器人语义理解支持纯中文场景、纯英文场景对话。</p>
              </div>
            </div>
          </div>

          <!-- media 介绍 -->
          <div class="media-right">
            <img
              src="@/assets/img/intelligent-im/hodler-media-image.png"
              alt=""
            />
          </div>
        </div>
      </div>
    </section>

    <!-- 应用场景 -->
    <section class="application-scene-wrap">
      <div class="application-scene">
        <common-title title="应用场景" />

        <div class="application-scene-main">
          <div class="item-left"></div>

          <div class="item-middle"></div>

          <div class="item-right">
            <div class="intro">
              <h6 class="subtitle">买卖家沟通</h6>
              <p class="text">
                买卖家im对接，售前抓住商机、售后解决买家问题。缓解跨时区、跨语言经营问题。
              </p>
            </div>
            <div class="intro">
              <h6 class="subtitle">社媒粉丝评论维护</h6>
              <p class="text">接口对接，自动回复社媒账号下图文/视频评论。</p>
            </div>
            <div class="intro">
              <h6 class="subtitle">智能询盘</h6>
              <p class="text">基于对话式的买家询盘，商机信息收集及总结。</p>
            </div>
          </div>
        </div>
      </div>
    </section>

    <!-- 尾部 -->
    <FooterVNode />
  </div>
</template>

<script>
import ViewHeader from '../../components/viewheader/viewheader.vue';
import BannerHead from './components/Banner.vue';
import CommonTitle from './components/CommonTitle.vue';
import FooterVNode from '@/components/foot/footer.vue';
import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import { ScrollToPlugin } from 'gsap/ScrollToPlugin';
gsap.registerPlugin(ScrollTrigger);

export default {
  name: 'IntelligentIM',

  components: {
    ViewHeader,
    BannerHead,
    CommonTitle,
    FooterVNode,
  },

  data() {
    return {};
  },

  mounted() {
    this.$nextTick(() => {
      this.initScroll();
    });
  },

  methods: {
    // 初始化滚动特效
    initScroll() {
      // 核心区域介绍图动画
      gsap.to('.media-right', {
        scrollTrigger: {
          trigger: '.media-right',
          start: 'top 350px', // 当 .media-right 顶部到达视口顶部时开始
          end: () =>
            `+=${document.querySelector('.content-left').offsetHeight - 560}px`, // 可能需要调整以匹配左侧内容的实际高度
          pin: true, // 固定元素
          pinSpacing: false, // 当元素被固定时，不会添加额外的空间
          scrub: true, // 平滑地根据滚动进行动画
        },
      });

      // 核心区域标题介绍动画
      gsap.to('.core-function .common-title', {
        scrollTrigger: {
          trigger: '.core-function .common-title',
          start: 'top 144px', // 当 .media-right 顶部到达视口顶部时开始
          end: () =>
            `+=${document.querySelector('.content-left').offsetHeight - 640}px`, // 可能需要调整以匹配左侧内容的实际高度
          pin: true, // 固定元素
          pinSpacing: false, // 当元素被固定时，不会添加额外的空间
          scrub: true, // 平滑地根据滚动进行动画
        },
      });

      // 核心区域标题介绍渐隐渐出动画
      document
        .querySelectorAll('.text-block__target')
        .forEach((block, index, blocks) => {
          gsap.fromTo(
            block,
            {
              opacity: 0,
              scrollTrigger: {
                trigger: block,
                start: 'top 350px',
                end: 'top 760px',
                scrub: true,
              },
            },
            {
              opacity: 1,
              scrollTrigger: {
                trigger: block,
                start: 'top 1500px',
                end: 'bottom center',
                scrub: true,
                onEnter: () => gsap.to(block, { opacity: 1, duration: 0.5 }),
                onLeave: () => gsap.to(block, { opacity: 0, duration: 0.7 }),
                onEnterBack: () =>
                  gsap.to(block, { opacity: 1, duration: 0.5 }),
                onLeaveBack: () =>
                  gsap.to(block, { opacity: 0, duration: 0.5 }),
              },
            }
          );
        });
    },

    // 跳转至 im
    jump() {
      window.location.href = 'https://aishipgo.com/manage/#/';
    },
  },
};
</script>

<style lang="less" scoped>
.gap {
  height: 80px;
}

.banner-wrap {
  background-image: url('~@/assets/img/intelligent-im/banner-bg.png');
  background-size: cover;
  background-repeat: no-repeat;
  transform: translateY(-80px);

  .show-content {
    position: relative;
    width: 1440px;
    height: 720px;
    left: 50%;
    transform: translateX(-50%);

    .jump-to-btn {
      position: absolute;
      left: 50%;
      transform: translateX(-50%);
      top: 41.66%;
      width: 200px;
      height: 56px;
      border-radius: 100px;
      background: #6c38e0;
      cursor: pointer;

      .text {
        position: absolute;
        top: 15px;
        left: 20px;
        line-height: 26px;
        font-size: 18px;
        color: #ffffff;
      }

      .icon {
        display: inline-block;
        position: absolute;
        top: 8px;
        right: 8px;
        width: 40px;
        height: 40px;
        background: #ffffff;
        border-radius: 20px;
        background-image: url(~@/assets/img/intelligent-im/arrow-hover.png);
        background-size: 50% 50%;
        background-repeat: no-repeat;
        background-position: center;
      }
    }
  }

  img {
    position: absolute;
  }

  .banner1 {
    width: 58.68%;
    left: 5.55%;
    bottom: 5.55%;
  }

  .banner2 {
    width: 28.47%;
    right: 5.55%;
    bottom: 5.55%;
  }
}

/* 核心功能 */
.core-function {
  position: relative;
  padding: 80px 0 24px;
  width: 1280px;
  margin: auto;

  /deep/ .common-title {
    h3 {
      line-height: 56px;
      font-size: 40px;
      font-weight: 600;
    }
  }

  .site-section__holder {
    padding-right: 80px;
  }

  .sticky-media-holder {
    position: relative;
    display: flex;
    justify-content: space-between;

    .content-left {
      flex: 0 0 33.985%;
      max-width: 33.985%;
      padding-right: 24px;
      height: 100%;

      .text-block__target {
        box-sizing: border-box;
        padding-top: 193px;
        height: 560px;

        .text-block__subtitle {
          line-height: 34px;
          font-weight: 600;
          color: #000108;
          font-size: 24px;
          margin-bottom: 8px;
        }

        .text-block__text {
          line-height: 22px;
          font-size: 16px;
          color: #494b5a;
        }
      }
    }

    .media-right {
      flex: 0 0 66.015%;
      max-width: 66.015%;
      max-height: 560px !important;

      background-repeat: no-repeat;
      background-size: 100% auto;
    }
  }
}

/* 应用场景 */
.application-scene-wrap {
  width: 100%;
  background: #f8f9fa;

  .application-scene {
    width: 1280px;
    margin: auto;
    padding-top: 80px;
    padding-bottom: 80px;

    .application-scene-main {
      margin-top: 40px;
      display: flex;
      gap: 24px;
      height: 520px;
      background-color: #6133c9;
      border-radius: 16px;
      padding: 24px;

      & > div {
        flex: 1;
        background-size: 100% 100%;
        background-repeat: no-repeat;
        background-position: center;
      }

      .item-left {
        background-image: url(~@/assets/img/intelligent-im/floor-3-left.png);
      }

      .item-middle {
        background-image: url(~@/assets/img/intelligent-im/floor-3-middle.png);
      }

      .item-right {
        display: flex;
        flex-direction: column;
        padding: 24px 0;
        gap: 40px;
      }

      .intro {
        color: #ffffff;

        .subtitle {
          font-size: 24px;
          font-weight: 600;
          line-height: 34px;
          margin-bottom: 8px;
        }
        .text {
          line-height: 22px;
          font-size: 16px;
        }
      }
    }
  }
}

@media screen and (max-width: 990px) {
}

@media screen and (min-width: 991px) and (max-width: 1280px) {
  .banner-wrap {
    .show-content {
      width: 992px;
      height: 495.1px;
    }
  }

  .core-function {
    width: 992px;

    .sticky-media-holder {
      .media-right {
        img {
          width: 793px;
          height: 525px;
        }
      }
    }
  }

  .application-scene-wrap .application-scene {
    width: 992px !important;

    .application-scene-main {
      height: 403px;
    }
  }
}

@media screen and (min-width: 1440px) {
}
</style>
